
.trivselregler-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
  }
  
  .trivselregler-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    
  }
  
  .rules-list {
    list-style-type: none;
    padding: 0;
  }
  
  .rule-item {
    background-color: #fff;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #555;
    
  }
  