.blog {
  padding: 2rem;
}

.blog ul {
  list-style: none;
  padding: 0;
}

.blog li {
  margin-bottom: 1rem;
  cursor: pointer;
}

.blog li:hover {
  text-decoration: underline;
}

.blog-post {
  padding: 2rem;
}

.blog-post button {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.blog-post-title {
  font-size: 2.6rem;
  font-family: Lato, sans-serif;
}

.blog-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.blog-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0; /* Adjust margins for better spacing */
  padding: 15px;
  border: 1px solid rgb(1, 1, 10);
  border-radius: 10px;
  background-color: #f9f9f9;
}

.collapsed {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-height: 2.4rem;
  font-family: 'Lato', sans-serif;
}

.expanded {
  white-space: normal;
  font-family: 'Lato', sans-serif;
  font-size: 1.38rem;
  margin-bottom: 20px;
  font-weight: 300;
  line-height: 1.6rem;
}

button {
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 10px 15px; /* Add padding for better touch targets */
  font-size: 1rem; /* Ensure font size is readable */
  width: auto; /* Ensure the button width is auto */
  margin: 10px auto; /* Center the button and add vertical margin */
  display: block; /* Make the button a block element */
}

button:hover {
  background-color: #ddd;
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .blog {
    padding: 1rem; /* Reduce padding for smaller screens */
  }

  .blog-post {
    padding: 1rem; /* Reduce padding for smaller screens */
  }

  .blog-post-title {
    font-size: 2rem; /* Adjust font size for readability */
  }

  .blog-content {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .blog-item {
    margin: 10px 0; /* Adjust margins for better spacing */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  button {
    padding: 8px 12px; /* Adjust padding for smaller screens */
    font-size: 0.9rem; /* Adjust font size for readability */
    width: auto; /* Ensure the button width is auto */
    margin: 10px auto; /* Center the button and add vertical margin */
    display: block; /* Make the button a block element */
  }
}