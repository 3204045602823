.footer-container {
  background-color: #101522;
  padding: 4rem 0 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: auto; /* Ensure the footer is pushed to the bottom */
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 300px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items p {
  margin-bottom: 8px;
}