/* Add keyframes for the flying animation */
@keyframes flyIn {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
.video-container {
    position: relative;
}

/* Improve text readability */
.biography {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
}

/* Style the text to fly over the video */
.fly-in-video {
    position: absolute;
    top: 20%;
    left: 20%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Ensure text is above video */
    animation: flyIn 2s ease-out; /* Apply the animation */
    color: rgb(243, 241, 241); /* Ensure text is visible over the video */
    font-size: 5rem;
}

/* Style the heading */
.biography h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    color: #222;
    animation: flyIn s ease-out; /* Apply the animation */
}

/* Improve text readability */
.biography {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
}



/* Style the heading */
.biography h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    color: #222;
}

/* Improve text readability */
.biography {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
}

/* Add spacing between paragraphs */
.biography p {
    margin-bottom: 1rem; 
    font-size: 1.1875rem;
    line-height: 1.6;
    font-family: 'Lato', sans-serif;
    margin-left: 2rem;
    margin-right: 2rem;
    background-color: #f9f9f9
}

/* Style the video */
.hero-video-biography {
    width: 100%;
    height: auto;
    margin-bottom: 20px; /* Add margin to separate video from text */
    z-index: -1; /* Ensure video is behind text */
    position: relative; /* Ensure proper stacking context */
}
