:root {
    --primary-color: #007bff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    border-radius: 15px;
    background-color: #fff;
    color: #242424;
    border: 1px solid var(--primary-color);
    font-size: 1px;
}

.btn--outline {
    background-color: transparent
    color(#fff);
    padding: 8px 90px;
    border: 1px solid var(--primary-color);
    transition: all 0.3s ease-out;
}

.btn--small {
    padding: 6px 13px;
    font-size: 14px;
}


.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}    

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background-color: #fff;
    color: #242424;
    transition: 250ms;
}