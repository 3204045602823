/* Admin.css */
.admin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .admin-input {
    margin: 10px 0;
    padding: 10px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .admin-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .admin-button:hover {
    background-color: #0056b3;
  }