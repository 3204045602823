* {
  box-sizing: border-box;
  margin: 0;
  padding: 20;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
/* Ensure the body and html take up the full height */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Flexbox container for the entire app */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main content should take up remaining space */
.main-content {
  flex: 1;
}

/* .services {
  background-image: url("../public/images/img-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('../public/images/img-9.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('../public/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */


