.cards {
  padding: 2rem 1rem; /* Adjust padding for smaller screens */
  background: #fff;
}

.animate__bounceIn {
  padding: 10px;
}

h1 {
  text-align: center;
  margin-top: 50px;
}

.cards__items > li {
  margin: 3px; /* Add margin to each card item */
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
  margin-top: 50px;
}

.cards__item {

  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  flex-direction: column;
  width: 400px;
  margin-left: -20px;

}

.cards__item_second {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  flex-direction: column;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  flex: 1;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  animation: flyIn 2s ease-out;
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.3);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

/* Adjustments for 15-inch monitors (1366px width) */
@media (max-height: 1300px) {
  .content__blog__container {
    width: 84%;
  }  

  .cards__item {
    margin-top: -100px;
  }
  .cards__item {
    margin-bottom: 2rem;
    width: 300px;
  
  }
}


@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 768px) {
  .cards {
    padding: 1rem; /* Reduce padding for smaller screens */
  }

  .cards__items {
    
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    align-items: center;
  }

  .cards__item {
    margin: 1rem 0; /* Adjust margin for better spacing */
    width: 100%; /* Ensure cards take full width */
    height: auto; /* Adjust height for smaller screens */
  }
  .cards__item {
    width: 350px;
    margin-left: -20px;
  }
  .cards__item__info {
    padding: 15px 20px; /* Adjust padding for smaller screens */
  }

  .cards__item__text {
    font-size: 16px; /* Adjust font size for readability */
    line-height: 22px;
  }
}