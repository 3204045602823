.media-container {
    position: relative;
    width: 100%; 
    height: auto;    
 
}

/* 
.media-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0) 90%,
      rgba(255, 255, 255, 0.5) 100%
  ),
  linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0) 90%,
      rgba(255, 255, 255, 0.5) 100%
  );
  pointer-events: none; /* Ensure the overlay doesn't interfere with interactions */
  /* z-index: 1; Ensure the overlay is above the image */
/* } */




/* Define the fadeInLeft animation */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(30%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
  
  .hero-media {
    width: 100%;
    /* height: 100%;
    max-height: 500px; */
    z-index: -1; /* Ensure media is behind text */
    position: relative; /* Ensure proper stacking context */
    visibility: visible;
    /* animation-name: fadeInLeft;
    animation-duration: 1200ms;
    animation-delay: 100ms;
    animation-fill-mode: both; Ensures the element stays in the final state */
  }

.fly-in-text {
    position: absolute;
    top: var(--text-top, 50%);
    left: var(--text-left, 50%);
    transform: translate(-50%, -50%);
    z-index: 1; /* Ensure text is above media */
    animation-name: fadeInLeft; /* Apply the animation */
    color: rgb(243, 241, 241); /* Ensure text is visible over the media */
    font-size: 2rem; /* Adjust this value to make the text bigger */
}

/* Add keyframes for the flying animation */
@keyframes flyIn {
    0% {
        transform: translate(-50%, -50%) translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -50%) translateX(0);
        opacity: 1;
    }
}

.hero-media {
    object-fit: cover; /* Ensure the image covers the container without stretching */
    max-width: 100%; /* Ensure the image does not exceed the container's width */
}


