:root {
  --navbar-height: 80px;
  --navbar-bg: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  --navbar-color: #fff;
  --hover-bg: #fff;
  --hover-color: #242424;
}

.navbar {
  transition: top 0.3s;
}

.navbar-visible {
  top: 0;
}

.navbar-hidden {
  top: -100px; /* Adjust this value based on your navbar height */
}

.navbar {
  background: var(--navbar-bg);
  height: var(--navbar-height);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}


/* Define the initial state of the navigation bar */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  transition: top 0.3s ease;
  z-index: 1000; /* Ensure the navbar is above other content */
}

/* Define the hidden state of the navigation bar */
.navbar-hidden {
  top: -100px; /* Adjust this value based on the height of your navbar */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: var(--navbar-height);
  z-index: 1;
  width: 100%;
  padding: 0 10px;
}

.navbar-logo {
  color: var(--navbar-color);
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.menu-icon {
  display: none;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
}

.nav-item {
  height: var(--navbar-height);
}

.nav-links {
  color: var(--navbar-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-links:hover {
  transition: all 0.2s ease-out;
  background: var(--hover-bg);
  color: var(--hover-color);

}

.nav-menu.active {
  background: var(--navbar-bg);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - var(--navbar-height));
  position: absolute;
  top: var(--navbar-height);
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: var(--navbar-height);
  left: 0;
  background: var(--navbar-bg);
  width: 100vw; /* Full viewport width */
  margin: 0; /* Remove any margins */
  padding: 0; /* Remove any padding */
  z-index: 1;
}

.dropdown-link {
  color: var(--navbar-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
}

.dropdown-link:hover {
  background: #575757;
}

.fas {
  margin-left: 8px; 
}



@media screen and (max-width: 960px) {

  .nav-item {
    margin-right: 30px;
    margin-left: -20px;
  }

  .navbar-container {
    position: relative;
  }

  .menu-icon {
    color: var(--navbar-color);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - var(--navbar-height));
    position: absolute;
    top: var(--navbar-height);
    left: -150%;
    opacity: 1;
    
  }

  .nav-menu.active {
    background: var(--navbar-bg);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    border-radius: 0;
  }

  .dropdown-menu {
    margin-left: 0; /* Ensure no left margin */
    position: static;
    background: var(--navbar-bg);
    width: 100vw; /* Full viewport width */
  }
  

  .navbar-logo {
    display: flex;
    align-items: center;
  }
  
  .navbar-logo-text {
    margin-right: 0.5rem;
  }

  @media screen and (max-width: 420px) {
    .navbar-logo-text {
      display: none;
    }
  }
}